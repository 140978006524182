<template>
    <table class="tftable">
        <tr>
            <td class="start-3"><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="0" data-value="A"></td>
            <td><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="1" data-value="C"></td>
            <td><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="2" data-value="E"></td>
            <td class="start-1"><input type="text" class="letter acetazolamida tiazidia" data-word="acetazolamida" data-idx="3" data-value="T"></td>
            <td><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="4" data-value="A"></td>
            <td><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="5" data-value="Z"></td>
            <td><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="6" data-value="O"></td>
            <td><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="7" data-value="L"></td>
            <td><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="8" data-value="A"></td>
            <td><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="9" data-value="M"></td>
            <td><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="10" data-value="I"></td>
            <td><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="11" data-value="D"></td>
            <td><input type="text" class="letter acetazolamida" data-word="acetazolamida" data-idx="12" data-value="A"></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="text" class="letter tiazidia" data-word="tiazidia" data-idx="1" data-value="I"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="text" class="letter tiazidia" data-word="tiazidia" data-idx="2" data-value="A"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="text" class="letter tiazidia" data-word="tiazidia" data-idx="3" data-value="Z"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td class="start-2"><input type="text" class="letter firenenona" data-word="firenenona" data-idx="0" data-value="F"></td>
            <td><input type="text" class="letter firenenona tiazidia" data-word="firenenona" data-idx="1" data-value="I"></td>
            <td><input type="text" class="letter firenenona" data-word="firenenona" data-idx="2" data-value="R"></td>
            <td><input type="text" class="letter firenenona" data-word="firenenona" data-idx="3" data-value="E"></td>
            <td><input type="text" class="letter firenenona" data-word="firenenona" data-idx="4" data-value="N"></td>
            <td><input type="text" class="letter firenenona" data-word="firenenona" data-idx="5" data-value="E"></td>
            <td><input type="text" class="letter firenenona" data-word="firenenona" data-idx="6" data-value="N"></td>
            <td><input type="text" class="letter firenenona" data-word="firenenona" data-idx="7" data-value="O"></td>
            <td><input type="text" class="letter firenenona" data-word="firenenona" data-idx="8" data-value="N"></td>
            <td><input type="text" class="letter firenenona" data-word="firenenona" data-idx="9" data-value="A"></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="text" class="letter tiazidia" data-word="tiazidia" data-idx="5" data-value="D"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><input type="text" class="letter tiazidia" data-word="tiazidia" data-idx="6" data-value="A"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </table>
</template>

<script>
export default {
    name: "CrossWord",
    data(){
        return {
            result : [],
            utils: require('../../../../shared/utils')
        };
    },
    watch:{
        '$store.state.game.question'(oldVal, newVal){
            this.check_end();
        }
    },
    created() {
        this.restore_data();
        $('.letter').on("keyup", this.letter_handler);
    },
    unmounted() {
        $('.letter').off("keyup", this.letter_handler);
    },
    mounted(){
        this.restore_data();
        $('.letter').on("keyup", this.letter_handler);
        this.check_end();
    },
    methods:{
        check_end(){
            var user = this.$store.state.game.users.find((usr) => usr.user == this.$store.state.game.user);
            if(user){
                var user_data = user.data;
                if(user_data){
                    var activity = user_data.find((q) => q.question == this.$store.state.game.question.id);
                    if(!activity)
                    {
                        if(this.$store.state.game.question.ended == true)
                        {
                            this.show_result();
                            this.lock_activity();
                        }
                    }
                }
            }
            if(this.$store.state.game.role == this.utils.USER_ROLES.MANAGER)
            {
                if(this.$store.state.game.question.ended == true)
                {
                    this.show_result();
                    this.lock_activity();
                }
            }
        },
        restore_data(){
            this.destroy_data();
            var user = this.$store.state.game.users.find((usr) => usr.user == this.$store.state.game.user);
            if(user){
                var user_data = user.data;
                if(user_data){
                    var activity = user_data.find((q) => q.question == this.$store.state.game.question.id);
                    if(activity)
                    {
                        if(activity.choice.length == 3){
                            this.show_result();
                        }
                    }
                }
            }
        },
        lock_activity(){
            var letters = $('.letter').toArray();
            letters.forEach((lett) => {
                $(lett).prop( "disabled", true );
            });
        },
        show_result(){
            var letters = $('.letter').toArray();
            letters.forEach((lett) => {
                $(lett).addClass('correct');
                $(lett).val($(lett).attr("data-value"));
            });
            this.lock_activity();
        },
        destroy_data(){
            var letters = $('.letter').toArray();
            letters.forEach((lett) => {
                $(lett).removeClass('correct');
                $(lett).val("");
                $(lett).prop( "disabled", false );
            });
        },
        check_input_value(target){
            target = target || $(target.target)
            if(target.val().length>1)
            {
                target.val(target.val().slice(-1));
            }
            if(target.val() == target.data("value"))
            {
                target.addClass("correct");
            }else{
                target.removeClass("correct");
            }

        },
        letter_focus(target){
            target = target || $(target.target)
            if(target.hasClass("correct"))return;
            target.val("");
        },
        cursor_move(word, idx){
            $('.'+word+"[data-idx='"+idx+"']").focus();
            this.letter_focus($('.'+word+"[data-idx='"+idx+"']"));
        },
        check_words(){
            var letters = $('.letter').toArray();
            var count = letters.length;
            let correct_count = 0;
            letters.forEach((lett) => {
                if($(lett).hasClass('correct')){
                    correct_count++;
                }
            });
            if(count == correct_count){
                $('.letter:focus').blur();
                window.network.wso.send(
                    JSON.stringify(
                    {
                        action: window.RPC.UPDATE_ACTIVITY, 
                        params:{
                            question: this.$store.state.game.question.id,
                            choice: this.$store.state.game.question.correctAnswer
                        }
                    })
                );
                this.lock_activity();
            }
        },
        letter_handler(evt){
            let word_array = evt.target.dataset.word.split('|');
            word_array.forEach((word) =>{
                let idx = parseInt(evt.target.dataset.idx)+1;
                if(evt.key === 'Backspace'){
                    if(evt.target.value == ""){
                        this.check_input_value($(evt.target));
                    }else{
                        var val = evt.target.value;
                        evt.target.value = '';
                        evt.target.value = val;
                    }
                    this.cursor_move(word, parseInt(idx-2));
                    return;
                } else if (evt.keyCode >= 65 || evt.which >= 65 && evt.keyCode <= 90 || evt.which <= 90) { // upper
                    $(evt.target).val(evt.target.value.toUpperCase());
                    this.check_input_value($(evt.target));
                    this.cursor_move(word, parseInt(idx));
                } else if (evt.keyCode >= 97 || evt.which >= 97 && evt.keyCode <= 122 || evt.which <= 122) {
                    $(evt.target).val(evt.target.value.toUpperCase());
                    this.check_input_value($(evt.target));
                    this.cursor_move(word, parseInt(idx));
                }
                this.check_words();
            });
        }
    }
}
</script>

<style scoped>
.tiazidia{
    background: rgb(255, 243, 130, 0.404);
}
.firenenona{
    background: rgb(149, 255, 130, 0.404);
}
.acetazolamida{
    background: rgba(130, 203, 255, 0.404);
}

.tftable td{
    width: 5vw;
    height: 5vw;
}
.tftable td input{
    width: 5vw;
    height: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3.5vw;
    color: white;
}
.correct{
    background-color: rgba(149, 255, 237, 0.589) !important;
}

.start-1::before{
    content: '1';
    position: absolute;
    margin-left: 6px;
    font-weight: bold;
    font-family: "Nulshock Rg";
    font-size: 1.3vw !important;
}

.start-2::before{
    content: '2';
    position: absolute;
    margin-left: 6px;
    font-weight: bold;
    font-family: "Nulshock Rg";
    font-size: 1.3vw !important;
}

.start-3::before{
    content: '3';
    position: absolute;
    margin-left: 6px;
    font-weight: bold;
    font-family: "Nulshock Rg";
    font-size: 1.3vw !important;
}
</style>