<template>
    <div v-if="form">
        {{ form }}
        <h1>Form Order type</h1>
        <input type="name" v-model="type"/>
        <input type="name" v-model="name"/>
    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default: null,
            required: true
        }
    },
    data(){
        return {
            type: "",
            name: "",
            title: "",
            choices: [],
            correctAnswer: ""
        }
    },
}
</script>

<style>

</style>