<template>
    <div id="left-circle-text" class="text-white animate__animated animate__fadeInLeft animate__delay-1s me-3">PROGRESO<br/>GLOBAL</div>
    <div class="position-relative animate__animated animate__bounceIn animate__delay-1s">
        <img id="left-circle-img" src="@/assets/ranking-circle-left.png">
        <div id="left-circle-percent" class="position-absolute top-0 start-0 w-100 h-100 text-white d-flex justify-content-center align-items-center animate__animated animate__fadeIn animate__delay-1s">{{ global_progress }}%</div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            global_progress: 0
        };
    },
    mounted(){
        this.load_global_progress();
    },
    updated(){
        this.load_global_progress();
    },
    methods: {
        load_global_progress(){
            let total_questions = this.$store.state.game.questions.length;
            let finished_count = this.$store.state.game.questions.filter((q) => q.ended == true).length;
            let progress = ((finished_count * 100) / total_questions);
            this.global_progress = (parseInt(progress)) ? parseInt(progress) : 0;
        }
    }
}
</script>

<style>
    #left-circle-img{
        width: 10vw;
    }
    
    #left-circle-text, #left-circle-percent{
        font-family: "Nulshock Rg";
        font-size: 1.2vw;
    }
</style>