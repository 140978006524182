<template>
    <div class="about position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center flex-column">
        <h1 class="text-white">LOGIN</h1>
        <div class="form-group mt-3">
            <input type="text" class="form-control" id="username" v-model="user" placeholder="Nombre de usuario" required/>
        </div>
        <button class="btn btn-danger mt-3" @click="login">Entrar</button>
    </div>
</template>

<script>
export default {
    data(){
        return {
            user: null,
        };
    },
    mounted(){
        $('#username').on("keyup", function(evt){
            var code = evt.key;
            if(code==="Enter")
            {
                this.login();
            }
        }.bind(this));
    },
    methods:{
        login(){
            window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_LOGIN, params: this.user}));
        }
    }
}
</script>

<style>

</style>
