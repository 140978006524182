<template>
    <div class="title d-flex justify-content-center align-items-center flex-column" v-if="$store.state.game.question">
        <!--titlevec :title="'PREGUNTA '+ question.id" :text="question.title"></titlevec-->
        <div class="row w-100 h-100 d-flex justify-content-center align-items-center flex-column">
            <div class="col-xl-8 col-lg-9 col-md-12 col-sm-12">
                <img class="w-100" id="title-bg" @load="image_loaded" src="@/assets/pregunta.png"/>
                <div v-if="loaded" id="title-region" class="position-absolute">{{ 'PREGUNTA '+ $store.state.game.question.id }}</div>
                <div v-if="loaded" id="text-region" class="position-absolute">{{ $store.state.game.question.title }}</div>
            </div>
        </div>
        <div v-if="$store.state.game.role == utils.USER_ROLES.PLAYER">
            ESPERE
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            utils: require('../../../shared/utils'),
            loaded: false,
        };
    },
    created() {
        window.addEventListener("resize", this.adjust_title);
    },
    unmounted() {
        window.removeEventListener("resize", this.adjust_title);
    },
    updated(){
        this.adjust_title();
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
    },
    mounted(){
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
    },
    methods:{
        image_loaded(){
            this.loaded = true;
            this.adjust_title();
        },
        adjust_title(){
            if(this.load == false) return;
            var title_img = $('#title-bg');
            if(title_img.length){
                var pos = title_img.position();
                var w = title_img.width();
                var h = title_img.height();

                var title_y = 15 * h / 100;
                var title_x = 5 * w / 100;
                var title_h = h * 15 / 100;
                var title_w = w - (10 * w / 100);

                var text_y = 35 * h / 100;
                var text_x = 5 * w / 100;
                var text_h = h - h;
                var text_w = w - (10 * w / 100);
                
                $('#title-region').css({
                    top: title_y + pos.top,
                    left: title_x + pos.left,
                    width: title_w,
                    height: title_h,
                });
                $('#text-region').css({
                    top: text_y + pos.top,
                    left: text_x + pos.left,
                    width: text_w,
                    height: text_h,
                });
            }
        },
    }
}
</script>

<style scoped>
    .title{
        background-image: url(@/assets/bg.jpg);
        background-position: center;
        background-size: cover;
        user-select: none;
    }

    #title-region{
      	font-family: "Nulshock Rg";
	  	font-size: 2.3vw !important;
		color: #c7f9f8 !important;
    }
    #text-region{
      	font-family: "Nulshock Rg";
	  	font-size: 1.5vw !important;
		color: #c7f9f8 !important;
    }
</style>
