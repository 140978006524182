import utils from "../../shared/utils";
import router from "./router";

var desired_path = null;

const client = {
    client_join(params){        
        if(params == null)
            return;
        
        window.store.commit("setGame", params);
        client.handle_client();
    },
    game_state(params){        
        if(params == null)
            return;

        var game = Object.assign({},window.store.state.game);
        game.state = params.state;
        window.store.commit("setGame", game);

        client.handle_client();
    },
    client_auth(params){        
        if(params == null)
            return;

        window.store.commit("setGame", params);

        client.handle_client();
    },
    client_update(params){        
        if(params == null)
            return;

        var game = Object.assign({}, window.store.state.game);

        game.users = params.users;
        game.questions = params.questions;
        game.state = params.state;
        game.current_question = params.current_question;
        game.question = params.question;

        window.store.commit("setGame", game);
        client.handle_client();
    },
    client_toast(params){        
        window.toast.success("aaa");
    },
    client_clean(params){
        if(window.store.state.game.role == utils.USER_ROLES.PLAYER){
            utils.reset_client();
        }
    },
    client_clean(params){
        if(params == true && window.store.state.game.role == utils.USER_ROLES.MANAGER)
        {
            utils.reset_client();
            return;
        }
        else if(params == null && window.store.state.game.role == utils.USER_ROLES.PLAYER){
            utils.reset_client();
        }
    },
    client_reload(params){
        if(window.store.state.game.role == utils.USER_ROLES.PLAYER){
            window.location.href="/";
        }
    },
    client_ping(params){
        window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_PING, params: null}))
    },
    edit_activity(params){
    },
    update_view(params){
        var user = window.store.state.game.users.find((usr) => usr.user == params.user);
        if(user)
        {
            user.current_view = params.current_view;
        }
    },
    // Utils
    handle_routes(){
        // This function will handle public routes.
        var path = window.location.pathname;
        if(path != null && window.store.state.game.role == utils.USER_ROLES.GUEST)
        {
            if(path == "/login")
            {
                desired_path = "Login";
            }
        }
    },
    handle_client(){
        client.handle_routes();

        if(window.store.state.game.role == utils.USER_ROLES.ADMINISTRATOR)
        {
            window.router.push({name: "Panel"});
        }else{
            var choice = null;
            var game = Object.assign({},window.store.state.game);
            var user = game.users.find((usr) => usr.user == game.user);
            if(user){
                if(user.data.length>0){
                    var answer = user.data.find((dat)=> dat.question == game.question.id)
                    if(answer){
                        choice = answer.choice;
                    }
                }
            }
            window.store.commit("setCurrentChoice",choice);

            switch(window.store.state.game.state){
                case window.utils.GAME_STATES.IDLE:
                    window.router.push({name: "Home"});
                    break;
                case window.utils.GAME_STATES.AUTH:
                    if(window.store.state.game.role == utils.USER_ROLES.PLAYER)
                        window.router.push({name: "Lobby"});
                    else
                        window.router.push({name: "Auth"});
                    break;
                case window.utils.GAME_STATES.TITLE:
                    if(window.store.state.game.role == utils.USER_ROLES.PLAYER || window.store.state.game.role == utils.USER_ROLES.MANAGER)
                        window.router.push({name: "Title"});
                    else
                        window.router.push({name: "Home"});
                    break;
                case window.utils.GAME_STATES.CHOICES:
                    if(window.store.state.game.role == utils.USER_ROLES.PLAYER || window.store.state.game.role == utils.USER_ROLES.MANAGER)
                        window.router.push({name: "Activity"});
                    else
                        window.router.push({name: "Home"});
                    break;
                case window.utils.GAME_STATES.RESULT:
                    if(window.store.state.game.role == utils.USER_ROLES.PLAYER || window.store.state.game.role == utils.USER_ROLES.MANAGER)
                        window.router.push({name: "Result"});
                    else
                        window.router.push({name: "Home"});
                    break;
                case window.utils.GAME_STATES.RANKING:
                    window.router.push({name: "Ranking"});
                    break;
                case window.utils.GAME_STATES.STOPPED:
                    window.router.push({name: "Stop"});
                    break;
                case window.utils.GAME_STATES.PAUSED:
                    window.router.push({name: "Pause"});
                    break;
                case window.utils.GAME_STATES.END:
                    if(window.store.state.game.role == utils.USER_ROLES.PLAYER || window.store.state.game.role == utils.USER_ROLES.MANAGER)
                        window.router.push({name: "End"});
                    else
                        window.router.push({name: "Home"});
                    break;
            }
            
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
        }
        // If there was any of a desired public path then go to the route.
        if(desired_path)
        {
            window.router.push({name: desired_path});
            desired_path = null;
        }
    }
};


export default client;