<template>
    <div class="position-relative animate__animated animate__bounceIn animate__delay-2s">
        <img id="right-circle-img" src="@/assets/ranking-circle-right.png">
        <div id="right-circle-percent" class="position-absolute top-0 start-0 w-100 h-100 text-white d-flex justify-content-center align-items-center animate__animated animate__fadeIn animate__delay-2s">{{ percent_correct_answers*10 }}%</div>
    </div>
    <div id="right-circle-text" class="text-white animate__animated animate__fadeInRight animate__delay-2s ms-3">PORCENTAJE<br/>ACIERTOS<br/>PREGUNTA <b v-if="$store.state.game.question">{{ $store.state.game.question.id }}</b></div>
</template>

<script>
export default {
    data(){
        return {
            percent_correct_answers: 0
        };
    },
    mounted(){
        this.load_hit_percentage();
    },
    watch:{
        '$store.state.game.users'(){
            this.load_hit_percentage();
        }
    },
    methods: {
        load_hit_percentage(){
            if(this.$store.state.game.question){
                this.percent_correct_answers = 0;
                this.$store.state.game.users.forEach((usr) => {
                    if(usr.data.length>0)
                    {
                        var once = true;
                        usr.data.forEach((dat) => {
                            if(dat.question == this.$store.state.game.question.id)
                            {
                                dat.choice.forEach((cho) => {
                                    if(this.$store.state.game.question.correctAnswer.includes(cho) && once)
                                    {
                                        once = false;
                                        this.percent_correct_answers++;
                                    }
                                });
                            }
                        });
                    }
                });
            }
        },
    }
}
</script>

<style>
    #right-circle-img{
        width: 10vw;
    }
    
    #right-circle-text, #right-circle-percent{
        font-family: "Nulshock Rg";
        font-size: 1.2vw;
    }
</style>