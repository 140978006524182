<template>
    <div v-if="form" class="mt-3">
        <div class="mb-3">
            <label for="formControlTitle" class="form-label">Title:</label>
            <input type="text" :class="'form-control'+((error_title) ? ' border-danger' : '')" id="formControlTitle" placeholder="Set the title of the quiz" v-model="form.title"/>
            <small v-if="error_title" class="text-danger">This field is required</small>
        </div>
        <!--div class="mb-3">
            <label for="formControlChoices" class="form-label">Choices:</label>
            <div class="row mb-3">
                <div class="col">
                    <div class="input-group">
                        <div class="input-group-text">
                            <input class="form-check-input mt-0" type="radio" name="correctAnswer" @change="form.correctAnswer = 1" value="1">
                        </div>
                        <input type="text" :class="'form-control' + ((form.correctAnswer == 1) ? ' bg-true' : ' bg-false')" v-model="form.choices[0]" placeholder="Choice 1">
                    </div>
                    <small v-if="error_choices[0] == true" class="text-danger">This field is required</small>
                </div>
                <div class="col">
                    <div class="input-group">
                        <div class="input-group-text">
                            <input class="form-check-input mt-0" type="radio" name="correctAnswer" @change="form.correctAnswer = 2" value="2">
                        </div>
                        <input type="text" :class="'form-control' + ((form.correctAnswer == 2) ? ' bg-true' : ' bg-false')" v-model="form.choices[1]" placeholder="Choice 2">
                    </div>
                    <small v-if="error_choices[1] == true" class="text-danger">This field is required</small>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="input-group">
                        <div class="input-group-text">
                            <input class="form-check-input mt-0" type="radio" name="correctAnswer" @change="form.correctAnswer = 3" value="3">
                        </div>
                        <input type="text" :class="'form-control' + ((form.correctAnswer == 3) ? ' bg-true' : ' bg-false')" v-model="form.choices[2]" placeholder="Choice 3">
                    </div>
                    <small v-if="error_choices[2] == true" class="text-danger">This field is required</small>
                </div>
                <div class="col">
                    <div class="input-group">
                        <div class="input-group-text">
                            <input class="form-check-input mt-0" type="radio" name="correctAnswer" @change="form.correctAnswer = 4" value="4">
                        </div>
                        <input type="text" :class="'form-control' + ((form.correctAnswer == 4) ? ' bg-true' : ' bg-false')" v-model="form.choices[3]" placeholder="Choice 4">
                    </div>
                    <small v-if="error_choices[3] == true" class="text-danger">This field is required</small>
                </div>
            </div>
        </div>
        <small v-if="error_correctAnswer == true" class="text-danger">Please mark the correct answer</small-->
        
        <div class="mb-3">
            <label for="formControlDuration" class="form-label">Duration:</label>
            <input type="number" :class="'form-control'+((error_duration) ? ' border-danger' : '')" id="formControlDuration" placeholder="Set the question duration" v-model="form.duration"/>
            <small v-if="error_duration" class="text-danger">This field is required</small>
        </div>
        <div class="mb-3">
            <label for="formControlScore" class="form-label">Score:</label>
            <input type="number" :class="'form-control'+((error_score) ? ' border-danger' : '')" id="formControlScore" placeholder="Set the score" v-model="form.score"/>
            <small v-if="error_score" class="text-danger">This field is required</small>
        </div>
    </div>
</template>

<script>
import utils from '../../../../shared/utils'
export default {
    props: {
        form: {
            type: Object,
            default: null,
            required: true
        }
    },
    data(){
        return {
            error_name: false,
            error_title: false,
            error_choices: [
                false,
                false,
                false,
                false
            ],
            error_score: false,
            error_correctAnswer: false,
            error_duration: false,
        };
    },
    mounted(){
        //this.form.type = utils.ACTIVITY_TYPE.QUIZ;
        this.form.validate = this.validate;
        //this.form.disabled = false;
    },
    methods: {
        validate(){
            // Check form name
            if(this.form.name == '' || this.form.name == null)
                this.error_name = true;
            else
                this.error_name = false;
            // Check form title
            if(this.form.title == '' || this.form.title == null)
                this.error_title = true;
            else
                this.error_title = false;
            // Check form choices
            if(this.form.choices[0] == '' || this.form.choices[0] == null)
                this.error_choices[0] = true;
            else
                this.error_choices[0] = false;
            if(this.form.choices[1] == '' || this.form.choices[1] == null)
                this.error_choices[1] = true;
            else
                this.error_choices[1] = false;
            if(this.form.choices[2] == '' || this.form.choices[2] == null)
                this.error_choices[2] = true;
            else
                this.error_choices[2] = false;
            if(this.form.choices[3] == '' || this.form.choices[3] == null)
                this.error_choices[3] = true;
            else
                this.error_choices[3] = false;
            // Check form correctAnswer
            if(this.form.correctAnswer == '' || this.form.correctAnswer == null)
                this.error_correctAnswer = true;
            else
                this.error_correctAnswer = false;

            // Check form score
            if(this.form.score == '' || this.form.score == null)
                this.error_score = true;
            else
                this.error_score = false;

            // Check form duration
            if(this.form.duration == '' || this.form.duration == null)
                this.error_duration = true;
            else
                this.error_duration = false;

            if(this.error_name == false && this.error_title == false && 
                this.error_choices[0] == false && 
                this.error_choices[1] == false && 
                this.error_choices[2] == false && 
                this.error_choices[3] == false && 
                this.error_correctAnswer == false && 
                this.error_score == false && 
                this.error_duration == false)
                return true;
            else
                return false;
        }
    }
}
</script>

<style>

</style>