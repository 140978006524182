<template>
    <div class="title w-100 h-100 d-flex justify-content-center align-items-center flex-column" v-if="$store.state.game.question">
        <video autoplay muted id="video_fondo_seleccion" class="p-0" poster="@/assets/default-bg.jpg">
            <source src="/videos/video_fondo_seleccion.mp4" type="video/mp4">
        </video>
        <div v-if="$store.state.game.role == utils.USER_ROLES.PLAYER" class="row w-100 h-100 d-flex justify-content-center align-items-center flex-column">
            <div class="col-xl-8 col-lg-9 col-md-12 col-sm-12">
                <img class="w-100 position-relative" id="title-bg" @load="image_loaded" src="@/assets/pregunta.png"/>
                <div v-if="loaded" id="title-region" class="position-absolute">{{ 'PREGUNTA '+ $store.state.game.question.id }}</div>
                <div v-if="loaded" id="text-region" class="position-absolute">{{ $store.state.game.question.title }}</div>
            </div>
            <div v-if="$store.state.game.role == utils.USER_ROLES.PLAYER">
                <div class="position-relative d-flex justify-content-center align-items-center mt-3 loader-container">
                    <span class="loader"></span>
                    <h3 class="text-wait position-absolute">ESPERE</h3>
                </div>
            </div>
        </div>
        <div v-if="$store.state.game.role == utils.USER_ROLES.MANAGER" class="row w-100 h-100 d-flex justify-content-center align-items-center flex-column">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <img class="w-100 position-relative" id="title-bg" @load="image_loaded" src="@/assets/pregunta.png"/>
                <div v-if="loaded" id="title-region-manager" class="position-absolute">{{ 'PREGUNTA '+ $store.state.game.question.id }}</div>
                <div v-if="loaded" id="text-region-manager" class="position-absolute">{{ $store.state.game.question.title }}</div>
            </div>
            <div v-if="$store.state.game.role == utils.USER_ROLES.PLAYER">
                <div class="position-relative d-flex justify-content-center align-items-center mt-3 loader-container">
                    <span class="loader"></span>
                    <h3 class="text-wait position-absolute">ESPERE</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from '@/components/Title.vue'
export default {
    components: {
        Title
    },
    data(){
        return {
            utils: require('../../../shared/utils'),
            loaded: false,
        };
    },
    created() {
        window.addEventListener("resize", this.adjust_title);
    },
    unmounted() {
        window.removeEventListener("resize", this.adjust_title);
    },
    updated(){
        this.adjust_title();
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
    },
    mounted(){
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
    },
    methods:{
        image_loaded(){
            this.loaded = true;
            this.adjust_title();
        },
        adjust_title(){
            if(this.load == false) return;
            var title_img = $('#title-bg');
            if(title_img.length){
                var pos = title_img.position();
                var w = title_img.width();
                var h = title_img.height();

                var title_y = 15 * h / 100;
                var title_x = 5 * w / 100;
                var title_h = h * 15 / 100;
                var title_w = w - (10 * w / 100);

                var text_y = 35 * h / 100;
                var text_x = 5 * w / 100;
                var text_h = h - h;
                var text_w = w - (10 * w / 100);
                
                $('#title-region').css({
                    top: title_y + pos.top,
                    left: title_x + pos.left,
                    width: title_w,
                    height: title_h,
                });
                $('#text-region').css({
                    top: text_y + pos.top,
                    left: text_x + pos.left,
                    width: text_w,
                    height: text_h,
                });
                $('#title-region-manager').css({
                    top: title_y + pos.top,
                    left: title_x + pos.left,
                    width: title_w,
                    height: title_h,
                });
                $('#text-region-manager').css({
                    top: text_y + pos.top,
                    left: text_x + pos.left,
                    width: text_w,
                    height: text_h,
                });
            }
        },
    }
}
</script>

<style scoped>
    #video_fondo_seleccion{
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }
    .title{
        user-select: none;
    }

    #title-region{
        font-family: "Nulshock Rg";
        font-size: 2.3vw !important;
        color: #c7f9f8 !important;
    }
    #text-region{
        font-family: "Nulshock Rg";
        font-size: 1.5vw !important;
        color: #c7f9f8 !important;
    }

    #title-region-manager{
        font-family: "Nulshock Rg";
        font-size: 2.7vw !important;
        color: #c7f9f8 !important;
    }
    #text-region-manager{
        font-family: "Nulshock Rg";
        font-size: 1.9vw !important;
        color: #c7f9f8 !important;
    }

    .loader-container{
        color:rgb(110, 161, 255);
    }
    .loader {
        width: 10vw;
        height: 10vw;
        border: 15px solid rgb(170, 211, 250);
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    .text-wait{
      	font-family: "Nulshock Rg";
        font-size: 50px !important;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 
</style>
