var sound = {
    bank: [],
    loadAudioBank:function(assets){
        assets.forEach((audio) => {
            sound.bank.push({id: audio.id, audio: new Audio(audio.asset)});
        });
    },
    playAudio: function (id, cur_time) {
        var audio_obj = sound.bank.find((aud) => aud.id == id);
        if(audio_obj){
            audio_obj.audio.addEventListener('ended', function () {
                audio_obj.audio.currentTime = 0;
            }, false)
            audio_obj.audio.play();
        }
    },
    pauseAudio: function (id, cur_time) {
        console.log("pauseAudio")
        console.log(id)
        var audio_obj = sound.bank.find((aud) => aud.id == id);
        if(audio_obj){
            audio_obj.audio.pause();
        }
    }
};

export default sound;