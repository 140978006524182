<template>
    <div class="home w-100 h-100">
        <video autoplay muted id="myVideo">
            <source src="@/assets/intro.mp4" type="video/mp4">
        </video>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data(){
        return {
            utils: require('../../../shared/utils'),
        };
    },
    unmounted(){
        window.sound.pauseAudio('intro');
    },
    mounted(){
        if(this.$store.state.game.role == this.utils.USER_ROLES.MANAGER)
        {
            window.sound.playAudio('intro', 10, 600);
        }
    },
    created(){
    }
}
</script>

<style scoped>
    .home{
        user-select: none;
    }
    
    #myVideo {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }
</style>