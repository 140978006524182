<template>
    <template v-if="$store.state.game.question.choices.length == 3">
        <div class="row h-50 pt-2 pb-2">
            <div class="col h-100">
                <choice :locked="locked" :id="0" :choice="$store.state.game.question.choices[0]" @click="send_activity(0)"></choice>
            </div>
            <div class="col h-100">
                <choice :locked="locked" :id="1" :choice="$store.state.game.question.choices[1]" @click="send_activity(1)"></choice>
            </div>
        </div>
        <div class="row h-50 pt-2 pb-2 d-flex justify-content-center">
            <div class="row w-auto h-100">
                <div class="p-0 h-100">
                    <choice :locked="locked" :id="2" :choice="$store.state.game.question.choices[2]" @click="send_activity(2)"></choice>
                </div>
            </div>
        </div>
    </template>
    <template v-else-if="$store.state.game.question.choices.length == 4">
        <div class="row h-50 pt-2 pb-2">
            <div class="col h-100">
                <choice :locked="locked" :id="0" :choice="$store.state.game.question.choices[0]" @click="send_activity(0)"></choice>
            </div>
            <div class="col h-100">
                <choice :locked="locked" :id="1" :choice="$store.state.game.question.choices[1]" @click="send_activity(1)"></choice>
            </div>
        </div>
        <div class="row h-50 pt-2 pb-2">
            <div class="col h-100">
                <choice :locked="locked" :id="2" :choice="$store.state.game.question.choices[2]" @click="send_activity(2)"></choice>
            </div>
            <div class="col h-100">
                <choice :locked="locked" :id="3" :choice="$store.state.game.question.choices[3]" @click="send_activity(3)"></choice>
            </div>
        </div>
    </template>
    <template v-else-if="$store.state.game.question.choices.length == 5">
        <div class="row pt-2 pb-2" style="height:calc(33.3%)">
            <div class="col h-100">
                <choice :locked="locked" :id="0" :choice="$store.state.game.question.choices[0]" @click="send_activity(0)"></choice>
            </div>
            <div class="col h-100">
                <choice :locked="locked" :id="1" :choice="$store.state.game.question.choices[1]" @click="send_activity(1)"></choice>
            </div>
        </div>
        <div class="row pt-2 pb-2" style="height:calc(33.3%)">
            <div class="col h-100">
                <choice :locked="locked" :id="2" :choice="$store.state.game.question.choices[2]" @click="send_activity(2)"></choice>
            </div>
            <div class="col h-100">
                <choice :locked="locked" :id="3" :choice="$store.state.game.question.choices[3]" @click="send_activity(3)"></choice>
            </div>
        </div>
        <div class="row pt-2 pb-2 d-flex justify-content-center" style="height:calc(33.3%)">
            <div class="row w-auto h-100">
                <div class="p-0 h-100">
                    <choice :locked="locked" :id="4" :choice="$store.state.game.question.choices[4]" @click="send_activity(4)"></choice>
                </div>
            </div>
        </div>
    </template>
    <template v-else-if="$store.state.game.question.choices.length == 6">
        <div class="row h-50 pt-2 pb-2">
            <div class="col h-100">
                <choice :locked="locked" :id="0" :choice="$store.state.game.question.choices[0]" @click="send_activity(0)"></choice>
            </div>
            <div class="col h-100">
                <choice :locked="locked" :id="1" :choice="$store.state.game.question.choices[1]" @click="send_activity(1)"></choice>
            </div>
            <div class="col h-100">
                <choice :locked="locked" :id="2" :choice="$store.state.game.question.choices[2]" @click="send_activity(2)"></choice>
            </div>
        </div>
        <div class="row h-50 pt-2 pb-2">
            <div class="col h-100">
                <choice :locked="locked" :id="3" :choice="$store.state.game.question.choices[3]" @click="send_activity(3)"></choice>
            </div>
            <div class="col h-100">
                <choice :locked="locked" :id="4" :choice="$store.state.game.question.choices[4]" @click="send_activity(4)"></choice>
            </div>
            <div class="col h-100">
                <choice :locked="locked" :id="5" :choice="$store.state.game.question.choices[5]" @click="send_activity(5)"></choice>
            </div>
        </div>
    </template>
</template>

<script>
import Choice from '@/components/Choice.vue';
export default {
    name: "Quiz",
    components: {
        Choice
    },
    data(){
        return {
            utils: require("../../../../shared/utils"),
            locked: false,
            selected_choices: []
        };
    },
    mounted(){
        if(window.router.currentRoute._value.name == "Result")
            this.locked = true;

        this.adjust_activity();
    },
    methods:{
        adjust_activity(){
            console.log("adjust_activity");

            var BaseFontSize = 3;
            var BaseFontChars = 0;
            $('.box').each( function(index) {
                if($(this).text().length<20){
                    var NewFontChars = $(this).text().length
                    var NewFontSize = BaseFontSize - ( ( (NewFontChars - BaseFontChars) / NewFontChars) * BaseFontSize )
                    console.log(NewFontSize)
                    $(this).css('font-size', '4vw')
                    //$(this).css('font-size', '200px')
                }
            });
        },
        send_activity(choice){
            var user = this.$store.state.game.users.find((usr) => usr.user == this.$store.state.game.user);
            if(user){
                var user_data = user.data;
                if(user_data){
                    var activity = user_data.find((q) => q.question == this.$store.state.game.question.id);
                    if(activity && this.$store.state.game.question.type == this.utils.ACTIVITY_TYPE.QUIZ)
                    {
                        return;
                    }
                }
            }
            if(this.locked) return;
            if(this.$store.state.game.question.type == this.utils.ACTIVITY_TYPE.QUIZ)
                this.selected_choices = [];
            
            this.selected_choices.push(choice)

            window.network.wso.send(
                JSON.stringify(
                {
                    action: window.RPC.UPDATE_ACTIVITY, 
                    params:{
                        question: this.$store.state.game.question.id,
                        choice: this.selected_choices
                    }
                }
            ));
            
        }
    }
}
</script>

<style>

</style>