<template>
    <h1>Order type</h1>
</template>

<script>
export default {
    name: "Order"
}
</script>

<style>

</style>