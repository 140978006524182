const utils = {
    ID() {
        return '_' + Math.random().toString(36).substr(2, 9);
    },
    format_number(number) {
        return ('0' + number).slice(-2);
    },
    reset_client() {
        localStorage.clear();
        window.location.href = "/";
    },
    get_choice_character(idx){
        return "ABCDEFGHIJKLMNÑOPQRSTUVWXYZ".charAt(idx);
    },
    USER_ROLES : {
        ADMINISTRATOR:  1,
        PLAYER:         2,
        GUEST:          3,
        MANAGER:        4,
    },
    GAME_STATES : {
        IDLE:           1,
        AUTH:           2,
        TITLE:          3,
        CHOICES:        4,
        RESULT:         5,
        RANKING:        6,
        STOPPED:        7,
        PAUSED:         8,
        END:            9,
    },
    ACTIVITY_TYPE : {
        QUIZ:           0,
        QUIZ_MULTIPLE:  1,
        TRUE_FALSE:     2,
        RANGE:          3,
        ORDER:          4,
        TEXT:           5,
        CROSSWORD:      6,
    },
    get_activity_type(type){
        var activitylist = Object.keys(utils.ACTIVITY_TYPE);
        return activitylist[type];
    },
    get_activity_type_color(type){
        var activitylist = Object.keys(utils.ACTIVITY_TYPE);
        let color = "bg-secondary";
        switch(activitylist[type]){
            case "QUIZ":
                color = "bg-primary";
                break;
            case "QUIZ_MULTIPLE":
                color = "bg-secondary";
                break;
            case "TRUE_FALSE":
                color = "bg-secondary";
                break;
            case "RANGE":
                color = "bg-secondary";
                break;
            case "ORDER":
                color = "bg-secondary";
                break;
            case "TEXT":
                color = "bg-secondary";
                break;
            case "CROSSWORD":
                color = "bg-warning";
                break;
        }
        return color;
    },
};

module.exports = utils;