<template>
    <div class="activity row p-0 m-0 w-100 h-100 overflow-hidden" >
        <video autoplay muted id="video_fondo_seleccion" class="w-100 h-100 p-0">
            <source src="/videos/video_fondo_seleccion.mp4" type="video/mp4">
        </video>
        <template v-if="$store.state.game.question">
            <div :class="((title == true) ? 'd-none' : '')">
                <div v-if="$store.state.game.role == utils.USER_ROLES.PLAYER" class="position-absolute top-0 start-0 d-flex w-100 h-100 overflow-hidden">
                    <div class="col-3 left-side">
                        <div class="row">
                            <div>
                                <hit-percentage v-if="$store.state.current_choice != null"></hit-percentage>
                            </div>
                        </div>
                        <div class="row text-white">
                            <div>
                                <stats v-if="$store.state.current_choice != null"></stats>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex justify-content-center align-items-center flex-column text-center">
                                <img class="img-fluid team-avatar mb-4 animate__animated animate__zoomIn animate__delay-1s" :src="team_avatar()"/>
                                <span id="team-name" class="animate__animated animate__zoomIn">EQUIPO<br/>{{ $store.state.game.user.toUpperCase() }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-9 position-relative">
                        <div class="position-absolute top-0 start-0 divider"></div>
                        <div id="question-id" class="p-0 m-0 d-flex justify-content-between align-items-start">
                            <span class="ms-3 d-flex justify-content-start align-items-center animate__animated animate__fadeInDown animate__delay-0-5s">PREGUNTA {{ utils.format_number($store.state.game.question.id) }}</span>
                            <img class="animate__animated animate__fadeInRight animate__delay-0-5s question-title-modal" @click="question_title_modal" src="@/assets/interrogante.png">
                        </div>
                        <!-- Choices display format -->
                        <div id="question-body" class="ps-3 pe-3">
                            <template v-if="$store.state.game.question.type == utils.ACTIVITY_TYPE.QUIZ || $store.state.game.question.type == utils.ACTIVITY_TYPE.QUIZ_MULTIPLE">
                                <quiz></quiz>
                            </template>
                            <template v-if="$store.state.game.question.type == 6">
                                <cross-word></cross-word>
                            </template>
                        </div>
                    </div>
                </div>
                <div v-else class="d-flex position-absolute w-100 h-100 top-0 start-0 text-white overflow-hidden">
                    <!--div class="title w-100 h-100 d-flex justify-content-center align-items-center flex-column" v-if="$store.state.game.question">
                        <div class="row w-100 h-100 d-flex justify-content-center align-items-center flex-column">
                            <div class="col-xl-8 col-lg-9 col-md-12 col-sm-12">
                                <img class="w-100" id="title-bg" @load="image_loaded" src="@/assets/pregunta.png"/>
                                <div v-if="loaded" id="title-region" class="position-absolute">{{ 'PREGUNTA '+ $store.state.game.question.id }}</div>
                                <div v-if="loaded" id="text-region" class="position-absolute">{{ $store.state.game.question.title }}</div>
                            </div>
                            <div>
                                <div class="position-relative d-flex justify-content-center align-items-center mt-3">
                                    <vue-countdown class="clock d-flex justify-content-center align-items-center" :emit-events="true" @end="activity_ended" @progress="update_heart" :time="$store.state.game.question.duration * 1000" v-slot="{ minutes, seconds }">
                                        {{ ('0' + minutes).slice(-2) }}:{{ ('0' + seconds).slice(-2) }}
                                    </vue-countdown>
                                </div>
                            </div>
                        </div>
                    </div-->
                    <div class="col-3 left-side">
                        <div class="row">
                            <div class="position-relative d-flex justify-content-center align-items-center mt-3 animate__animated animate__fadeInDown">
                                <vue-countdown class="clock d-flex justify-content-center align-items-center" :emit-events="true" @end="activity_ended" @progress="update_heart" :time="$store.state.game.question.duration * 1000" v-slot="{ minutes, seconds }">
                                    {{ ('0' + minutes).slice(-2) }}:{{ ('0' + seconds).slice(-2) }}
                                </vue-countdown>
                            </div>
                        </div>
                        <div class="row text-white">
                            
                        </div>
                        <div class="row">
                            <div class="col d-flex justify-content-center align-items-center flex-column text-center text-white">
                                <div class="w-100 ps-4 pe-4" v-if="$store.state.logos">
                                    <img :class="'img-fluid img-logo animate__animated animate__fadeIn ' + (($store.state.logo == idx) ? 'd-none':'')" :src="logo" v-for="(logo, idx) in $store.state.logos">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-9 position-relative">
                        <div class="position-absolute top-0 start-0 divider"></div>
                        <div id="question-id" class="p-0 m-0 d-flex justify-content-between align-items-start">
                            <span class="ms-3 d-flex justify-content-start align-items-center animate__animated animate__fadeInDown animate__delay-0-5s">PREGUNTA {{ utils.format_number($store.state.game.question.id) }}</span>
                        </div>
                        <!-- Choices display format -->
                        <div id="question-body" class="ps-3 pe-3 animate__animated animate__fadeIn">
                            <span class="title-choice text-white mb-3">{{ $store.state.game.question.title }}</span>
                            <p class="title-choice text-manager-options text-white border p-2 mt-2" v-for="(choice, idx) in $store.state.game.question.choices">{{ utils.get_choice_character(idx) }}.- {{ choice }}</p>
                        </div>
                    </div>
                </div>
            </div>            
            <div v-if="title == true" class="position-absolute top-0 start-0 w-100 h-100 p-1 title-modal-bg">
                <div  class="row m-0 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeInDown">
                    <div class="d-flex justify-content-center align-items-center flex-column">
                        <span id="title-text-modal" class="text-center">{{ $store.state.game.question.title }}</span>
                        <div v-if="$store.state.game.question.type == utils.ACTIVITY_TYPE.CROSSWORD" class="col-8">
                            <p class="title-choice text-white" v-for="(choice, idx) in $store.state.game.question.choices">{{ idx+1 }}.- {{ choice }}</p>
                        </div>
                    </div>
                </div>
                <img class="position-absolute top-0 end-0 question-title-modal" @click="question_title_modal" src="@/assets/cerrar.png">
            </div>
        </template>
    </div>
</template>

<script>
import Quiz from '@/components/type/Quiz.vue'
import CrossWord from '@/components/type/CrossWord.vue'
import Stats from '@/components/Stats.vue';
import HitPercentage from '@/components/HitPercentage.vue';
import Title from '@/components/Title.vue'
export default {
    name: 'Activity',
    components: {
        Title,
        Quiz,
        CrossWord,
        Stats,
        HitPercentage
    },
    data(){
        return {
            utils: require('../../../shared/utils'),
            title: false,
            loaded: false,
            logo_idx: 0,
            team_avatar: function(){
                var img = null;
                try{
                    img = require('@/assets/' + window.store.state.game.user.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.png')
                }catch(e){
                    console.log(e)
                }
                return img; 
            }
        };
    },
    created() {
        window.addEventListener("resize", this.adjust_height);
    },
    unmounted() {
        window.removeEventListener("resize", this.adjust_height);
    },
    mounted(){
        this.adjust_height();
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
    },
    updated(){
        this.adjust_height();
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
    },
    methods:{
        image_loaded(){
            this.loaded = true;
            this.adjust_title();
        },
        adjust_title(){
            if(this.load == false) return;
            var title_img = $('#title-bg');
            if(title_img.length){
                var pos = title_img.position();
                var w = title_img.width();
                var h = title_img.height();

                var title_y = 15 * h / 100;
                var title_x = 5 * w / 100;
                var title_h = h * 15 / 100;
                var title_w = w - (10 * w / 100);

                var text_y = 35 * h / 100;
                var text_x = 5 * w / 100;
                var text_h = h - h;
                var text_w = w - (10 * w / 100);
                
                $('#title-region').css({
                    top: title_y + pos.top,
                    left: title_x + pos.left,
                    width: title_w,
                    height: title_h,
                });
                $('#text-region').css({
                    top: text_y + pos.top,
                    left: text_x + pos.left,
                    width: text_w,
                    height: text_h,
                });
            }
        },
        question_title_modal(){
            this.title = !this.title;
        },
        adjust_height(){
            this.adjust_title();
            $('#question-body').height($('#app').height() - $('#question-id').height());
        },
        activity_ended(){
            window.network.wso.send(JSON.stringify({action: window.RPC.END_ACTIVITY, params: window.store.state.game.question.id}));
        },
        update_heart(params){
            if(params.seconds>5)
                window.sound.playAudio('heart', 10, 600);
            else{
                window.sound.playAudio('heart', 10, 600);
                setTimeout(function(){
                    window.sound.playAudio('heart1', 10, 100);     
                }, 500);           
            }
        }
    }         
}
</script>

<style scoped>
    #video_fondo_ranking, #video_fondo_seleccion{
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    .text-manager-options{
        font-size: 1.8vw;
    }

    .left-side .row{
        height: calc(100% / 3);
    }

    #question-id{
        font-size: 3.5vw;
        letter-spacing: 8px;
    }

    #team-name{
        letter-spacing: 3px;
        font-size: 1.3vw !important;
    }

    #question-id, #team-name,.clock{
        font-family: "Nulshock Rg";
        color: white;
    }

    .team-avatar{
        width: 9vw;
        border-radius: 100%;
        border: 2px solid white;
        -webkit-box-shadow: 0px 0px 15px 7px white;
        -moz-box-shadow: 0px 0px 15px 7px white;
        box-shadow: 0px 0px 15px 7px white;
    }

    .divider{
        position: relative;
        height: 85%;
        width: 2px;
        background-color: rgba(255, 255, 255, 0.692);
    }

    .question-title-modal{
        width: 5vw;
    }
    .animate__delay-0-5s
    {
        --animate-delay: 0.5s;
    }


    #title-region{
      	font-family: "Nulshock Rg";
	  	font-size: 2.3vw !important;
		color: #c7f9f8 !important;
    }
    #text-region{
      	font-family: "Nulshock Rg";
	  	font-size: 1.5vw !important;
		color: #c7f9f8 !important;
    }

    .clock{
        width: 12vw;
        height: 12vw;
        border: 3px solid rgba(255, 255, 255, 0.623);
        border-radius: 100%;
        font-size: 2vw;
        color:rgb(110, 161, 255);
    }

    #title-text-modal{
        font-family: "Nulshock Rg";
        font-size: 2.5vw !important;
        color: #c7f9f8 !important;
    }

    .title-choice{
        font-size: 1.5vw;
    }
</style>
