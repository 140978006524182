<template>
    <h1>Form Range type</h1>
</template>

<script>
export default {
    
}
</script>

<style>

</style>