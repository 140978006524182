<template>
    <img id="app-bg" class="position-absolute top-0 start-0 w-100 h-100" src="@/assets/default-bg.jpg">
    <router-view class="w-100 h-100"/>
    <Loading></Loading>
    <div v-if="debug" class="debug position-fixed bottom-0 start-0 bg-light p-2 border border-secondary m-2 overflow-auto">
        <div v-if="debug_menu">
            <span><b>Network status:</b> 
                <label v-if="$store.state.network">{{ $store.state.network }}</label>
                <label v-else>-</label>
            </span>
            <br/>
            <span v-if="$store.state.game">
                <b>User: </b> {{ ($store.state.game.user) ? $store.state.game.user : '-' }}
                <br/>
                <b>Role: </b> {{ ($store.state.game.role) ? $store.state.game.role : '-' }}
                <br/>
                <b>Game state: </b> <span v-if="$store.state.game.state">{{ $store.state.game.state }}</span>
                <br/>
                <b>Current question: </b> <span v-if="$store.state.game.current_question">{{ $store.state.game.current_question }}</span>
                <br/>
                <p v-if="$store.state.game.question">{{ $store.state.game.question }}</p>
                <p v-if="$store.state.game.questions.length>0">
                    <button class="btn btn-primary btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#collapseQuestions" aria-expanded="false" aria-controls="collapseQuestions">
                        Questions
                    </button>
                </p>
                <div class="collapse" id="collapseQuestions">
                    <div class="card card-body">
                        <pre><code>{{ $store.state.game.questions }}</code></pre>
                    </div>
                </div>
            </span>
            <hr>
            <nav>
                <button class="btn btn-danger btn-sm mb-3" @click="reset_client">Clean</button>
            </nav>
        </div>
        <button @click="toggle_debug_menu">X</button>
    </div>
    <button class="fs-button position-absolute top-0 start-0" @click="handle_fullscreen">
        <i v-if="fs" class="bi bi-fullscreen-exit"></i>
        <i v-else class="bi bi-arrows-fullscreen"></i>
    </button>
</template>

<script>
import utils from '../../shared/utils'
import Loading from '@/components/Loading.vue'
export default {
    components: {
        Loading
    },
    data(){
        return {
            utils: utils,
            debug: (process.env.NODE_ENV == 'development') ? true : false,
            debug_menu: false,
            fs: false,
            interval: null,
        };
    },
    unmounted(){
        if(this.interval != null)
            clearInterval(this.interval);
    },
    mounted(){
        if(this.debug){
            var dbg = localStorage.getItem('debug_menu');
            this.debug_menu = (dbg === 'true') ? true : false;
        }
          
        this.interval = setInterval(function(){
            window.store.state.logo++;
            if(window.store.state.logo > window.store.state.logos.length-1)
            {
                window.store.state.logo = 0;
            }
        }.bind(this), 8000);
        
    },
    methods: {
        reset_client(){
            utils.reset_client();
            localStorage.clear();
            window.location.href = "/";
        },
        toggle_debug_menu(){
            this.debug_menu = !this.debug_menu;
            localStorage.setItem('debug_menu', this.debug_menu);
        },
        handle_fullscreen() {
            var elem = document.documentElement;
            this.fs = !this.fs;
            if (this.fs)
                this.open_fullscreen(elem);
            else
                this.close_fullscreen(elem);            
        },
        open_fullscreen(elem) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        },
        close_fullscreen(elem) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    },
}
</script>

<style>
    @font-face {
        font-family: "Nulshock Rg";
        src: url('@/assets/fonts/nulshock-bd.otf');
    }

    @font-face {
        font-family: "Open Sans Regular";
        src: url("@/assets/fonts/OpenSans-Regular.ttf");
    }

    @font-face {
        font-family: "Open Sans Bold";
        src: url("@/assets/fonts/OpenSans-Bold.ttf");
    }

    html, body, #app{
        width: 100%;
        height: 100%;
        user-select: none;
    }

    #app-bg{
        
        /*background-image: url('@/assets/default-bg.jpg');*/
        background-size: cover;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .bg-true{
        background-color: rgb(185, 240, 185) !important;
    }
    .bg-false{
        background-color: rgb(240, 185, 185) !important;
    }

    .debug{
        max-height: 300px;
    }

    .fs-button {
        background: none;
        border: none;
        font-weight: bold;
        font-size: 2vw;
        color: aqua;
    }
</style>
