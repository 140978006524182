
const RPC = {
    CLIENT_JOIN         : 0,
    CLIENT_PING         : 1,
    CLIENT_LOGIN        : 2,
    CLIENT_UPDATE       : 3,
    GAME_STATE          : 4,
    CLIENT_AUTH         : 5,
    GET_CLIENTS         : 6,
    LAUNCH_TITLE        : 7,
    LAUNCH_ACTIVITY     : 8,
    UPDATE_ACTIVITY     : 9,
    END_ACTIVITY        : 10,
    CLIENT_TOAST        : 11,
    UPDATE_VIEW         : 12,
    CLIENT_CLEAN        : 13,
    CLIENT_RELOAD       : 14,
    EDIT_ACTIVITY       : 15,
    get_rpc_name(rpc_id){
        var rpclist = Object.keys(RPC);
        return rpclist[rpc_id];
    }
};

module.exports = RPC;