<template>
    <div class="panel position-absolute bg-light w-100">

        <!-- Modal new question -->
        <div class="modal fade" id="modalAddQuestion" tabindex="-1" aria-labelledby="modalAddQuestionLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalAddQuestionLabel">Nueva pregunta</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <select v-model="type" class="form-select" aria-label="Select question type">
                            <option value="0">Select question type</option>
                            <option value="1">Quiz</option>
                            <option value="2">True/false</option>
                            <option value="3">Range</option>
                            <option value="4">Order</option>
                            <option value="5">Text</option>
                        </select>

                        <div v-if="type != null">
                            <FormQuiz v-if="type == 1" :form="form"></FormQuiz>
                            <FormTrueFalse v-if="type == 2" :form="form"></FormTrueFalse>
                            <FormRange v-if="type == 3" :form="form"></FormRange>
                            <FormOrder v-if="type == 4" :form="form"></FormOrder>
                            <FormText v-if="type == 5" :form="form"></FormText>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="validate">Save changes</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal preview question -->
        <div class="modal fade" id="modalPreviewQuestion" tabindex="-1" aria-labelledby="modalPreviewQuestionLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalPreviewQuestionLabel">Preview question</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="question != null">
                            <Quiz v-if="question.type == 1" :question="question"></Quiz>
                            <TrueFalse v-if="question.type == 2" :question="question"></TrueFalse>
                            <Range v-if="question.type == 3" :question="question"></Range>
                            <Order v-if="question.type == 4" :question="question"></Order>
                            <Text v-if="question.type == 5" :question="question"></Text>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal delete question -->
        <div class="modal fade" id="modalDeleteQuestion" tabindex="-1" aria-labelledby="modalDeleteQuestionLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalDeleteQuestionLabel">Delete question</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="question" class="alert alert-primary" role="alert">
                            Do you want to continue deleting the question with title: <b>{{ question.title }}</b> and name: <b>{{ question.name }}</b>?
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal edit question -->
        <div class="modal fade" id="modalEditQuestion" tabindex="-1" aria-labelledby="modalEditQuestionLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalEditQuestionLabel">Edit question</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="question != null">
                            <FormQuiz :form="question"></FormQuiz>
                            <!--FormTrueFalse v-if="question.type == 2" :form="question"></FormTrueFalse>
                            <FormRange v-if="question.type == 3" :form="question"></FormRange>
                            <FormOrder v-if="question.type == 4" :form="question"></FormOrder>
                            <FormText v-if="question.type == 5" :form="question"></FormText-->
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="validate">Update</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row bg-light w-100 m-0">
            <div class="col-3 bg-light">
                <div class="row m-0 p-0">
                    <h3 class="p-0">Equipos:</h3>
                </div>
                <div class="row m-0 p-0">
                    <ul class="list-unstyled p-0">
                        <li class="border" v-for="user in $store.state.game.users">
                            <div v-if="user.is_alive == true" class="d-flex justify-content-center align-items-center flex-column p-2 bg-success bg-opacity-25">
                                <div class="d-flex justify-content-between align-items-center w-100">
                                    <div class="d-flex justify-content-start align-items-center flex-row">
                                        <h2 class="m-0 me-2 text-success">&#9679;</h2>
                                        <b>{{ user.user }} - [{{ calc_score(user.data) }}]</b>
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <button class="btn btn-sm btn-secondary ms-2" @click="client_reload(user.user)"><i class="bi bi-arrow-clockwise"></i></button>
                                    </div>
                                </div>
                                <small class="position-relative w-100" v-if="user.current_view">
                                    <b>Vista: </b>{{ user.current_view }} | <b>Actividad:</b> {{ (user.current_activity) ? user.current_activity : '-' }}
                                </small>
                            </div>
                            <div v-else class="d-flex justify-content-start align-items-center flex-row p-2 bg-secondary bg-opacity-10">
                                <h2 class="m-0 me-2 text-secondary">&#9679;</h2>
                                {{ user.user }}
                            </div>
                        </li>
                    </ul>
                    <template v-if="synced">
                        <div class="bg-danger" v-if="synced == false">
                            <span class="">Sin sincronizar</span>
                        </div>
                        <div class="bg-success" v-else>
                            <span class="">Sincronizados</span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="col-9 p-0">
                <div class="row m-0">
                    <div class="col-9 p-0">
                        <div class="row">
                            <div class="col d-flex justify-content-center align-items-center">
                                <h3 class="flex-grow-1">Preguntas:</h3>
                                <!--button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#modalAddQuestion">Add question</button-->
                            </div>
                        </div>
                        <div v-if="$store.state.game.questions.length>0" class="row">
                            <div class="accordion" id="accordionExample">
                                <div :class="'accordion-item' + (($store.state.game.current_question == question.id) ? ' bg-success bg-opacity-25' : '')" v-for="question in $store.state.game.questions">
                                    <h2 class="accordion-header" :id="'headingTwo'+question.id">
                                        <button :class="'accordion-button collapsed' + (($store.state.game.current_question == question.id) ? ' bg-success bg-opacity-25' : '')" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseTwo'+question.id" :aria-expanded="($store.state.game.current_question === question.id)" :aria-controls="'collapseTwo'+question.id">
                                            <div><b>#{{ question.id }}</b> -  <span :class="'badge ' + utils.get_activity_type_color(question.type)">{{ utils.get_activity_type(question.type) }}</span>  {{ question.title }}</div>
                                        </button>
                                    </h2>
                                    <div :id="'collapseTwo'+question.id" :class="'accordion-collapse collapse'+(($store.state.game.current_question == question.id) ? ' show' : '')" :aria-labelledby="'headingTwo'+question.id" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <button type="button" class="btn btn-sm btn-secondary me-2" @click="launch_title(question.id)">LANZAR PREGUNTA</button>
                                            <button type="button" class="btn btn-sm btn-success" @click="launch_activity(question.id)">COMENZAR ACTIVIDAD</button>

                                            <button type="button" class="btn btn-sm ms-2 btn-primary" data-bs-toggle="modal" data-bs-target="#modalEditQuestion" @click="set_question(question)">Editar</button>

                                            <!--button type="button" class="btn btn-sm ms-2 btn-primary" data-bs-toggle="modal" data-bs-target="#modalPreviewQuestion" @click="set_question(question)">Ver</button>
                                            <button type="button" class="btn btn-sm ms-2 btn-danger" data-bs-toggle="modal" data-bs-target="#modalDeleteQuestion" @click="set_question(question)">Borrar</button-->
                                            
                                            <br/>
                                            <b>Terminada: </b>{{ question.ended }}
                                            <br/>
                                            <!--
                                            <b>Tipo: </b> <span class="badge bg-primary">{{ utils.get_activity_type(question.type) }}</span>
                                            <br/>
                                            -->
                                            <b>Duración: </b> {{ (question.duration) ? question.duration : '-' }}
                                            <br/>
                                            <b>Puntuación: </b> {{ (question.score) ? question.score : '-' }}
                                            <br/>
                                            <b>Opciones: </b>
                                            <br/> 
                                            <div class="border mb-2 p-2 bg-light" v-for="choice in question.choices">
                                                <b>+</b> {{ choice }}
                                            </div>
                                            <br/>
                                            <b>Respuesta correcta: </b> {{ (question.correctAnswer) ? question.correctAnswer : '-' }}
                                            <br/>
                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 p-0">
                        <div class="row m-0">
                            <div class="col d-flex justify-content-center align-items-center">
                                <h3 class="flex-grow-1">Controles:</h3>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col d-flex flex-column">
                                <div class="row mb-2">
                                    <div class="col">
                                        <button type="button" class="btn btn-sm btn-secondary w-100" @click="game_state(utils.GAME_STATES.IDLE)">REINICIAR JUEGO</button>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col">
                                        <button type="button" class="btn btn-sm btn-secondary w-100" @click="game_state(utils.GAME_STATES.AUTH)">SELECCIÓN GRUPOS</button>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col">
                                        <button type="button" class="btn btn-sm btn-primary w-100" @click="game_state(utils.GAME_STATES.RANKING)">IR AL RANKING</button>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col">
                                        <button type="button" class="btn btn-sm btn-secondary w-100" @click="game_state(utils.GAME_STATES.END)">FIN JUEGO</button>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col">
                                        <button type="button" class="btn btn-sm btn-warning w-100" @click="client_clean(null)">EXPULSAR JUGADORES</button>
                                    </div>
                                    <div class="col">
                                        <button type="button" class="btn btn-sm btn-warning w-100" @click="client_clean(true)">EXPULSAR MANAGER</button>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col">
                                        <button type="button" class="btn btn-sm btn-warning w-100" @click="client_reload(null)">FORZAR RECARGA JUGADORES</button>
                                    </div>
                                </div>
                                
                                <!--div class="row mb-2">
                                    <div class="col">
                                        <button type="button" class="btn btn-sm btn-secondary w-100" @click="game_state(utils.GAME_STATES.STOPPED)">PARAR</button>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col">
                                        <button type="button" class="btn btn-sm btn-secondary w-100" @click="game_state(utils.GAME_STATES.PAUSED)">PAUSAR</button>
                                    </div>
                                </div-->
                            </div>
                        </div>
                        <!--div class="row m-0">
                            <ul class="list-unstiled">
                                <li><b>Estado del juego:</b>{{ $store.state.game.state }}</li>
                                <li><b>Pregunta actual:</b>{{ $store.state.game.current_question }}</li>
                            </ul>
                        </div-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="btn btn-warning position-fixed bottom-0 end-0 m-2" @click="logout"><i class="bi bi-box-arrow-left"></i></button>
</template>

<script>
import {Modal} from 'bootstrap'

import FormSession from '@/components/form/FormSession.vue'
import FormQuiz from '@/components/form/FormQuiz.vue'
import FormTrueFalse from '@/components/form/FormTrueFalse.vue'
import FormRange from '@/components/form/FormRange.vue'
import FormOrder from '@/components/form/FormOrder.vue'
import FormText from '@/components/form/FormText.vue'

import Quiz from '@/components/type/Quiz.vue'
import TrueFalse from '@/components/type/TrueFalse.vue'
import Range from '@/components/type/Range.vue'
import Order from '@/components/type/Order.vue'
import Text from '@/components/type/Text.vue'

export default {
    name: 'Panel',
    components: {
        FormSession,
        FormQuiz,
        FormTrueFalse,
        FormRange,
        FormOrder,
        FormText,

        Quiz,
        TrueFalse,
        Range,
        Order,
        Text
    }, 
    data(){
        return {
            type: 0,
            question : null,
            utils: require('../../../shared/utils'),
            form: {
                duration: null,
                score: null,
            },
            synced: null,
            // Modals
            add_question_modal: null,
        };
    },
    watch:{
        '$store.state.game.users'(oldVal, newVal){
        }
    },
    mounted(){
        // Add question modal
        this.add_question_modal = new Modal('#modalAddQuestion');
    },
    methods : {
        logout(){
            this.utils.reset_client();
            window.location.href = "/";
        },
        calc_score(data)
        {
            var score = 0;
            data.forEach(element => {
                var question = this.$store.state.game.questions.find((quest) => quest.id == element.question);
                if(question)
                {
                    var once = true;
                    element.choice.forEach((cho) => {
                        if(question.correctAnswer.includes(cho) && once){
                            once = false;
                            score += question.score;
                        }
                    })
                }
            });
            return score;
        },
        client_clean(flag){
            window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_CLEAN, params: flag}));
        },
        client_reload(id){
            window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_RELOAD, params: id}));
        },
        validate(){
            window.network.wso.send(JSON.stringify({action: window.RPC.EDIT_ACTIVITY, params: this.question}));
        },
        set_question(question){
            var qstion = Object.assign({}, question);
            if(typeof qstion.choices === 'string')
                qstion.choices = JSON.parse(qstion.choices);

            this.question = qstion;
        },
        game_state(state){
            window.network.wso.send(JSON.stringify({action: window.RPC.GAME_STATE, params: state}));
        },
        launch_title(question_id){
            window.network.wso.send(JSON.stringify({action: window.RPC.LAUNCH_TITLE, params: question_id}));
        },
        launch_activity(question_id){
            window.network.wso.send(JSON.stringify({action: window.RPC.LAUNCH_ACTIVITY, params: question_id}));
        },
    }
}
</script>

<style scoped>
    .bg-connected{
        background-color: rgb(0, 197, 49);
    }

    .bg-disconnected{
        background-color: rgb(173, 173, 173);
    }
</style>