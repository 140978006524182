<template>
    <div class="position-relative overflow-hidden choice h-100 d-none" :id="'choice-'+id" v-if="!locked">
        <span class="position-absolute top-0 end-0 text-white choice-id">{{ utils.get_choice_character(id) }}</span>
        <div :class="'box d-flex justify-content-center align-items-center ps-2 pe-2 ' + ((is_selected_choice(id)) ? 'active' : '')" style="--all:10px;--top-right: 50px">
            {{ choice }}
        </div>
    </div>
    <div class="position-relative overflow-hidden choice h-100 d-none" :id="'choice-'+id" v-else>
        <span class="position-absolute top-0 end-0 text-white choice-id">{{ utils.get_choice_character(id) }}</span>
        <div :class="'box d-flex justify-content-center align-items-center ps-2 pe-2 '+ ((is_selected_choice(id)) ? 'active' : '') + ' ' + ((is_correct_choice(id)) ? 'correct' : '')" style="--all:10px;--top-right: 50px">
            {{ choice }}
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                utils: require('../../../shared/utils'),
            };
        },
        props:{
            id: {
                type: Number,
                default: null,
                required: true
            },
            choice: {
                type: String,
                default: null,
                required: true
            },
            locked: {
                type: Boolean,
                default: false,
                required: false
            }
        },
        methods:{
            is_selected_choice(idx){
                return ((this.$store.state.current_choice != null && this.$store.state.current_choice.includes(idx)) ? true : false);
            },
            is_correct_choice(idx){
                return this.$store.state.game.question.correctAnswer.includes(idx);
            },
        },
        mounted(){
            setTimeout(function(){
                $('#choice-'+this.id).removeClass("d-none").addClass("animate__animated animate__bounceInUp");
            }.bind(this), this.id * 300);
        }
    }
</script>

<style scoped>
    .box {
        --all:0px;
        --b:rgba(255, 255, 255, 0.877);
        font-size: 1.8vw;
        font-family: "Open Sans Bold";
        color: var(--b);
        display:inline-block;
        border:2px solid var(--b);
        height: 100%;
        background:
            linear-gradient(  45deg, var(--b) 0 calc(var(--bottom-left,var(--all)) + 2px) ,transparent 0) bottom left /50% 50%,
            linear-gradient( -45deg, var(--b) 0 calc(var(--bottom-right,var(--all)) + 2px),transparent 0) bottom right/50% 50%,
            linear-gradient( 135deg, var(--b) 0 calc(var(--top-left,var(--all)) + 2px)    ,transparent 0) top left    /50% 50%,
            linear-gradient(-135deg, var(--b) 0 calc(var(--top-right,var(--all)) + 2px)   ,transparent 0) top right   /50% 50%,
            var(--img,rgba(0, 238, 255, 0));
        background-origin:border-box;
        background-repeat:no-repeat;
        -webkit-mask:
            linear-gradient(  45deg, transparent 0 var(--bottom-left,var(--all)) ,var(--b) 0) bottom left,
            linear-gradient( -45deg, transparent 0 var(--bottom-right,var(--all)),var(--b) 0) bottom right,
            linear-gradient( 135deg, transparent 0 var(--top-left,var(--all))    ,var(--b) 0) top left,
            linear-gradient(-135deg, transparent 0 var(--top-right,var(--all))   ,var(--b) 0) top right;
        -webkit-mask-size:50.5% 50.5%;
        -webkit-mask-repeat:no-repeat;
        transition: .1s;
    }

    .box:hover{
        transition:.1s;
        --b:rgba(0, 206, 189, 1);
        border-color:rgb(85, 255, 255,1);
        background-color: rgb(85, 255, 255,.16);
    }
    .active{
        transition:.1s;
        --b:rgba(0, 206, 189, 1);
        border-color:rgb(85, 255, 255,1);
        background-color: rgba(85, 255, 255, 0.253);
    }
    .correct{
        transition:.1s;
        --b:rgba(0, 206, 189, 1);
        border-color:rgb(85, 255, 255,1);
        background-color: rgba(85, 255, 255, 0.562);
        color: white;
    }

    .choice-id{
        font-size: 30px;
    }
</style>