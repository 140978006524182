<template>
    <div v-if="$store.state.loading" class="loading-view w-100 h-100 d-flex justify-content-center align-items-center flex-column position-fixed top-0 bg-light overflow-hidden">
        <h2>CARGANDO</h2>
        <span class="loader mt-3"></span>
    </div>
</template>

<script>
    export default {
    }
</script>

<style scoped>
.loading-view{
    background-image: url('@/assets/default-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "Nulshock Rg";
    color: white;
}
    .loader {
        width: 48px;
        height: 48px;
        border: 5px solid;
        border-color: rgba(166, 255, 255, 0.897) transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 
</style>