<template>
    <div class="activity row p-0 m-0 w-100 h-100 overflow-hidden" >
        <video autoplay muted id="video_fondo_respuesta" class="p-0">
            <source src="/videos/video_fondo_respuesta.mp4" type="video/mp4">
        </video>
        <template v-if="$store.state.game.question">
            <div :class="((title == true) ? 'd-none' : '')">
                <div  class="position-absolute top-0 start-0 d-flex w-100 h-100 overflow-hidden">
                    <div class="col-3 left-side">
                        <div class="row">
                            <div>
                                <hit-percentage></hit-percentage>
                            </div>
                        </div>
                        <div class="row text-white">
                            <div>
                                <stats></stats>
                            </div>
                        </div>
                        <div class="row">
                            <div v-if="$store.state.game.role == utils.USER_ROLES.PLAYER" class="col d-flex justify-content-center align-items-center flex-column text-center">
                                <img class="img-fluid team-avatar mb-4 animate__animated animate__zoomIn animate__delay-1s" :src="team_avatar()"/>
                                <span id="team-name" class="animate__animated animate__zoomIn">EQUIPO<br/>{{ $store.state.game.user.toUpperCase() }}</span>
                            </div>
                            <div v-else-if="$store.state.game.role == utils.USER_ROLES.MANAGER" class="col d-flex justify-content-center align-items-center flex-column text-center text-white">
                                <div class="w-100 ps-4 pe-4" v-if="$store.state.logos">
                                    <img :class="'img-fluid img-logo animate__animated animate__fadeIn ' + (($store.state.logo == idx) ? 'd-none':'')" :src="logo" v-for="(logo, idx) in $store.state.logos">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-9 position-relative">
                        <div class="position-absolute top-0 start-0 divider"></div>
                        <div id="question-id" class="p-0 m-0 d-flex justify-content-between align-items-start">
                            <span class="ms-3 d-flex justify-content-start align-items-center animate__animated animate__fadeInDown animate__delay-0-5s">PREGUNTA {{ utils.format_number($store.state.game.question.id) }}</span>
                            <img class="animate__animated animate__fadeInRight animate__delay-0-5s question-title-modal" @click="question_title_modal" src="@/assets/interrogante.png">
                        </div>
                        <!-- Choices display format -->
                        <div id="question-body" class="ps-3 pe-3">
                            <template v-if="$store.state.game.question.type == utils.ACTIVITY_TYPE.QUIZ || $store.state.game.question.type == utils.ACTIVITY_TYPE.QUIZ_MULTIPLE">
                                <quiz></quiz>
                            </template>
                            <template v-if="$store.state.game.question.type == 6">
                                <cross-word></cross-word>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="title == true" class="position-absolute top-0 start-0 w-100 h-100 p-1 title-modal-bg">
                <div  class="row m-0 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeInDown">
                    <div class="d-flex justify-content-center align-items-center flex-column">
                        <span id="title-text-modal" class="text-center">{{ $store.state.game.question.title }}</span>
                        <div v-if="$store.state.game.question.type == utils.ACTIVITY_TYPE.CROSSWORD" class="col-8">
                            <p class="title-choice text-white" v-for="(choice, idx) in $store.state.game.question.choices">{{ idx+1 }}.- {{ choice }}</p>
                        </div>
                    </div>
                </div>
                <img class="position-absolute top-0 end-0 question-title-modal" @click="question_title_modal" src="@/assets/cerrar.png">
            </div>
        </template>
    </div>
</template>

<script>
import Quiz from '@/components/type/Quiz.vue'
import CrossWord from '@/components/type/CrossWord.vue'
import Stats from '@/components/Stats.vue';
import HitPercentage from '@/components/HitPercentage.vue';
export default {
    name: 'Activity',
    components: {
        Quiz,
        CrossWord,
        Stats,
        HitPercentage
    },
    data(){
        return {
            utils: require('../../../shared/utils'),
            title: false,
            logo_idx: 0,
            team_avatar: function(){
                var img = null;
                try{
                    img = require('@/assets/' + window.store.state.game.user.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.png')
                }catch(e){
                    console.log(e)
                }
                return img; 
            }
        };
    },
    created() {
        window.addEventListener("resize", this.adjust_height);
    },
    unmounted() {
        window.removeEventListener("resize", this.adjust_height);
    },
    mounted(){
        //this.update_current_choice();
        this.adjust_height();
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
    },
    updated(){
        //this.update_current_choice();
        this.adjust_height();
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
    },
    methods:{
        question_title_modal(){
            this.title = !this.title;
        },
        adjust_height(){
            $('#question-body').height($('#app').height() - $('#question-id').height());
        },
    }         
}
</script>

<style scoped>
#video_fondo_ranking, #video_fondo_respuesta{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.left-side .row{
    height: calc(100% / 3);
}

#question-id{
    font-size: 3.5vw;
    letter-spacing: 8px;
}

#team-name{
    letter-spacing: 3px;
}

#question-id, #team-name{
    font-family: "Nulshock Rg";
    color: white;
}

.team-avatar{
    width: 9vw;
    border-radius: 100%;
    border: 2px solid white;
    -webkit-box-shadow: 0px 0px 15px 7px white;
    -moz-box-shadow: 0px 0px 15px 7px white;
    box-shadow: 0px 0px 15px 7px white;
}

.divider{
    position: relative;
    height: 85%;
    width: 2px;
    background-color: rgba(255, 255, 255, 0.692);
}

.question-title-modal{
    width: 5vw;
}
.animate__delay-0-5s
{
    --animate-delay: 0.5s;
}


#title-text-modal{
  	font-family: "Nulshock Rg";
  	font-size: 2.5vw !important;
	color: #c7f9f8 !important;
}

.title-choice{
    font-size: 1.5vw;
}
</style>
