<template>
    <div class="ranking w-100 h-100 overflow-hidden">
        <video autoplay muted id="video_fondo_ranking">
            <source src="/videos/video_fondo_ranking.mp4" type="video/mp4">
        </video>
        <div class="d-flex justify-content-center align-items-start ranking-header position-absolute w-100" id="ranking-header-region">
            <div class="position-relative d-flex justify-content-center align-items-center mt-3">
                <left-circle v-if="$store.state.game.questions.length"></left-circle>
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <img id="ranking-title" class="position-relative ms-4 me-4 animate__animated animate__fadeInDown" src="@/assets/ranking-title.png">
            </div>
            <div class="position-relative d-flex justify-content-center align-items-center mt-3">
                <right-circle></right-circle>
            </div>
        </div>
        <div class="w-100 h-100 d-flex justify-content-center position-relative ranking-body" id="ranking-body-region">
            <div :class="'h-100 d-flex justify-content-end align-items-center flex-column ' +  ((user.user == $store.state.game.user || $store.state.game.role == utils.USER_ROLES.MANAGER) ? 'team-normal' : 'team-opaque')" v-for="user in $store.state.game.users">
                <div :class="'team position-relative text-center animate__animated invisible d-flex justify-content-end align-items-center flex-column '" :data-user="user.id" :data-score="calc_score(user.data)">
                    <span class="text-white text-score" v-if="calc_score(user.data)>0">{{ calc_score(user.data) }}</span>
                    <img :src="team_avatar(user.user)" class="team-avatar"/>
                    <div :class="'name mb-3'+ (($store.state.game.user == user.user) ? ' shadow':'')">
                        EQUIPO <br/>{{ user.user }}
                    </div> 
                </div>
                <div class="line top-100 animate__animated invisible" :id="'line_' + user.id" :data-score="calc_score(user.data)"></div>
                <div class="invisible" v-if="watch_podium"></div>
            </div>
        </div>
    </div>
</template>

<script>
//import RankingHeader from '@/components/RankingHeader.vue'
import LeftCircle from '@/components/LeftCircle.vue'
import RightCircle from '@/components/RightCircle.vue'
export default {
    name: 'Ranking',
    components:{
        //RankingHeader
        LeftCircle,
        RightCircle
    },
    data(){
        return {
            idx: 0,
            percent_correct_answers: 0,
            total_score: null,
            utils: require('../../../shared/utils'),
        };
    },
    created() {
        window.addEventListener("resize", this.adjust_ranking);
    },
    unmounted() {
        window.removeEventListener("resize", this.adjust_ranking);
    },
    mounted(){
        this.adjust_ranking();
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))

        setTimeout(this.adjust_ranking,2000);
    },
    updated(){
        this.adjust_ranking();
        if(window.store.state.network == "connected")
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_VIEW, params: {view: window.router.currentRoute._value.name, activity: (window.store.state.game.question) ? window.store.state.game.question.id : null}}))
    },
    methods: {
        adjust_ranking(){
            var app = $("#app").height();
            var header = $("#ranking-header-region").outerHeight();
            if(app && header){
                $("#ranking-body-region").css({
                    paddingTop: header,
                });

                this.load_team();
            }
        },
        team_avatar: function(usr){
            var img = null;
            try{
                img = require('@/assets/' + usr.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.png')
            }catch(e){
                console.log(e)
            }
            return img; 
        },
        load_team(){
            let count = 3;
            var teams = $(".team").toArray();
            var ranking_body = $('.ranking-body').height();
            this.total_score = null;
            this.$store.state.game.questions.forEach((q) => {this.total_score += q.score;});
            teams.forEach((team, idx) =>{
                setTimeout(function(){
                    var user_id = $(team).attr("data-user");
                    $(team).removeClass("invisible")
                    $(team).addClass("animate__fadeInUp");
                    var score = $('#line_'+user_id).attr("data-score");
                    $('#line_'+user_id).removeClass("invisible")
                    var y = (score * ranking_body) / this.total_score;
                    $('#line_'+user_id).css('height', y + "px");
                    $('#line_'+user_id).addClass("animate__fadeInUp");
                        
                }.bind(this), 100 * idx);
            });
        },
        calc_score(data)
        {
            var score = 0;
            data.forEach(element => {
                var question = this.$store.state.game.questions.find((quest) => quest.id == element.question);
                if(question)
                {
                    var once = true;
                    element.choice.forEach((cho) => {
                        if(question.correctAnswer.includes(cho) && once){
                            once = false;
                            score += question.score;
                        }
                    })
                }
            });
            return score;
        },
        watch_podium(){
            console.log($('.team').toArray())
        }
    }
}
</script>

<style scoped>
    .ranking{
        /*background: radial-gradient(ellipse at top, #ed0002, transparent), radial-gradient(ellipse at top, #080203, #300101);*/
        color: #c7f9f8;
    }

    #video_fondo_ranking{
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    .team{
        font-family: "Nulshock Rg";
        font-size: 1vw;
    }

    .line{
        width: 30px;
        background-color: aqua;
    }

    .shadow{
        -webkit-animation: blink 3.0s linear infinite;
        -moz-animation: blink 3.0s linear infinite;
        -ms-animation: blink 3.0s linear infinite;
        -o-animation: blink 3.0s linear infinite;
        animation: blink 3.0s linear infinite;
    }

    @keyframes blink {
        0% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
        50% { text-shadow: none; }
        100% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
    }

    @-webkit-keyframes blink {
        0% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
        50% { text-shadow: 0 0 0; }
        100% { text-shadow: 1px 1px 2px #ff8f8f, 0 0 1em white, 0 0 0.2em white; }
    }

    .team-avatar{
        width: 8vw;
    }

    .ranking-circle{
        width: 10vw;
    }

    #ranking-title{
        width: 20vw;
        top: -15px;
    }

    .ranking-title-percents, .ranking-title-text{
        font-family: "Nulshock Rg";
        font-size: 1.2vw;
    }
    .ranking-title-text-percent{
        font-size: 1.5vw;
    }

    .text-score{
        font-size: 1.7vw;
    }

    .team-opaque{
        opacity: 0.6 !important;
    }


    #ranking-title-left-text{
        background-image: url(@/assets/ranking-circle-left.png);
    }

    
    @keyframes anim {
        from {
            background-color: aqua;
        }
        to {
            background-color: rgba(0, 255, 255, 0.336);
        }
    }

    .blink-team{
        animation-name: anim;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
</style>