<template>
    <div class="timer">
        
    </div>
</template>

<script>
export default {
    name: "Timer",
}
</script>

<style>

</style>