<template>
    <div class="lobby w-100 h-100 d-flex justify-content-center align-items-center flex-column text-center text-uppercase">
        <!--video autoplay muted loop id="myVideo">
            <source :src="team_bg($store.state.game.user)" type="video/mp4">
        </video-->
        <video autoplay muted loop id="myVideo">
            <source :src="'/videos/fondo_'+sanitize_name($store.state.game.user)+'.mp4'" type="video/mp4">
        </video>
        <div class="position-relative d-flex justify-content-center align-items-center text-center flex-column loader-container">
            
            <div v-if="$store.state.game.user" class="text-danger">
                <h1 class="fw-bolder text-team">EQUIPO</h1>
                <h1 class="fw-bolder text-team">{{ $store.state.game.user }}</h1>
            </div>
            <div class="position-relative d-flex justify-content-center align-items-center">
                <span class="loader"></span>
                <h3 class="text-wait position-absolute">ESPERE</h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Lobby",
    data(){
        return {
            team_bg: function(user){
                var vid = null;
                try{
                    vid = require('@/assets/fondo_' + user.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.mp4')
                }catch(e){
                    console.log(e)
                }
                return vid; 
            },
            sanitize_name(user){
                return user.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            }
        };
    },
}
</script>

<style scoped>
    .lobby{
        user-select: none;
    }
    .loader-container{
        color:rgb(110, 161, 255);
    }
    .loader {
        width: 10vw;
        height: 10vw;
        border: 15px solid rgb(170, 211, 250);
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 
    
    #myVideo {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .text-team{
      	font-family: "Nulshock Rg";
        text-shadow: 0px 0px 0px rgb(255, 255, 255), 0 0 1em rgb(255, 255, 255), 0 0 0.1em rgb(255, 255, 255);
        font-size: 11vw !important;
    }

    .text-wait{
      	font-family: "Nulshock Rg";
        font-size: 3vw !important;
    }
</style>
