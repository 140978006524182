<template>
    <div class="game position-relative">
        <div v-if="$store.state.questions[$store.state.current_question]">
            <Quiz v-if="$store.state.questions[$store.state.current_question].type == 1" :question="$store.state.questions[$store.state.current_question]" :send_response="send_response"></Quiz>
            <TrueFalse v-if="$store.state.questions[$store.state.current_question].type == 2" :question="$store.state.questions[$store.state.current_question]" :send_response="send_response"></TrueFalse>
            <Range v-if="$store.state.questions[$store.state.current_question].type == 3" :question="$store.state.questions[$store.state.current_question]" :send_response="send_response"></Range>
            <Order v-if="$store.state.questions[$store.state.current_question].type == 4" :question="$store.state.questions[$store.state.current_question]" :send_response="send_response"></Order>
            <Text v-if="$store.state.questions[$store.state.current_question].type == 5" :question="$store.state.questions[$store.state.current_question]" :send_response="send_response"></Text>
        </div>
    </div>
</template>

<script>

import Quiz from '@/components/type/Quiz.vue'
import TrueFalse from '@/components/type/TrueFalse.vue'
import Range from '@/components/type/Range.vue'
import Order from '@/components/type/Order.vue'
import Text from '@/components/type/Text.vue'

import Timer from '@/components/Timer.vue'
export default {
    name: 'Game',
    components: {
        Quiz,
        TrueFalse,
        Range,
        Order,
        Text,

        Timer,
    },
    mounted(){
        if(this.$store.state.questions[this.$store.state.current_question] !== undefined){
            this.$store.state.questions[this.$store.state.current_question].answer = null;
            this.$store.state.questions[this.$store.state.current_question].disabled = false;
        }
    },
    methods:{
        clear_interval(){
            if(this.$store.state.timer == null)
                return;
            clearInterval(this.$store.state.timer);
            this.$store.commit("setTimer", null);
            // Disable activity
            this.$store.state.question.disabled = true;
        },
        send_response(data){
            this.$store.state.questions[this.$store.state.current_question].disabled = true;
            var question = Object.assign({}, this.$store.state.questions[this.$store.state.current_question]);
            question.answer = data;
            window.store.commit("setQuestion", question);
            window.network.wso.send(JSON.stringify({action: window.RPC.UPDATE_STATS, params: question}));
        },
        goto_panel(){
            router.push({name:"Sessions"});
        }
    },
    watch: {
        '$store.state.current_question'(oldVal, newVal)
        {
            //this.update_question();
            //var secs = this.$store.state.question.duration;
            //this.$store.commit("setCounter", this.$store.state.question.duration);
            //this.$store.commit("setProgress", 100);
            //this.clear_interval();
            //var timer = setInterval(function(){
            //    var count = this.$store.state.counter;
            //    count--;
            //    this.$store.commit("setCounter", count);
            //    if(this.$store.state.counter <= 0){
            //        this.clear_interval();
            //        this.send_response();
            //    }
            //    
            //    this.$store.commit("setProgress", (this.$store.state.counter * 100 / this.$store.state.question.duration));
            //    secs--;
            //    this.$store.commit("setSeconds", secs);
            //}.bind(this), 1000);
            //
            //this.$store.commit("setTimer", timer);            
        }
    },
}
</script>
