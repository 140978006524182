import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
window.store = store;

import VueCountdown from '@chenfengyuan/vue-countdown';

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
window.toast = toast;

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "bootstrap-icons/font/bootstrap-icons.css";

import 'animate.css';

import jQuery from 'jquery';
window.$ = jQuery;

import utils from '../../shared/utils';
window.utils = utils;

import RPC from '../../shared/RPC';
window.RPC = RPC;

import client from './client';
window.client = client;

import network from './network';
window.network = network;

import sound from './sound';
window.sound = sound;

sound.loadAudioBank([
    {
        id: 'heart',
        asset: require('@/assets/heart.mp3')
    },
    {
        id: 'heart1',
        asset: require('@/assets/heart.mp3')
    },
    {
        id: 'intro',
        asset: require('@/assets/intro_musica.wav')
    },
    {
        id: 'lobby',
        asset: require('@/assets/general_fondo.mp3')
    }
]);

//createApp(App).use(store).use(router).mount('#app')

const app = createApp(App);
app.use(store).use(router);
//app.component(...<lots of components>);
app.component(VueCountdown.name, VueCountdown);
app.mount("#app");
window.app = app;
window.router = router;
app.config.performance = true;
app.config.devtools = true;

// Load local storage information.
window.store.commit("loadLocalStorage");

window.store.commit("setLogos",[    
    require("@/assets/Astrazeneca.png"),
    require("@/assets/redgdps.png"),
]);

window.network.connect(process.env.VUE_APP_HOST,process.env.VUE_APP_PORT,function(){
    
});