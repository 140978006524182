<template>
    <div class="auth w-100 h-100 d-flex justify-content-center align-items-center flex-column">
        <video autoplay muted loop id="video_fondo_seleccion" class="p-0">
            <source src="/videos/video_fondo_seleccion.mp4" type="video/mp4">
        </video>
        <div class="position-absolute top-0 start-0 w-100 h-100" v-if="$store.state.game.users.length>0">
            <div class="w-100 h-50 d-flex justify-content-around align-items-center">
                <div class="d-flex justify-content-center align-items-center flex-column text-white d-none animate__animated" v-if="$store.state.game.users[0].is_alive == false" id="team-av-0">
                    <span class="team-txt mb-4">EQUIPO<br/> {{$store.state.game.users[0].user}}</span>
                    <img class="team-av" :src="team_avatar($store.state.game.users[0].user)" @click="client_auth($store.state.game.users[0].user)">
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column text-white d-none animate__animated" v-if="$store.state.game.users[1].is_alive == false" id="team-av-1">
                    <span class="team-txt mb-4">EQUIPO<br/> {{$store.state.game.users[1].user}}</span>
                    <img class="team-av" :src="team_avatar($store.state.game.users[1].user)" @click="client_auth($store.state.game.users[1].user)">
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column text-white d-none animate__animated" v-if="$store.state.game.users[2].is_alive == false" id="team-av-2">
                    <span class="team-txt mb-4">EQUIPO<br/> {{$store.state.game.users[2].user}}</span>
                    <img class="team-av" :src="team_avatar($store.state.game.users[2].user)" @click="client_auth($store.state.game.users[2].user)">
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column text-white d-none animate__animated" v-if="$store.state.game.users[3].is_alive == false" id="team-av-3">
                    <span class="team-txt mb-4">EQUIPO<br/> {{$store.state.game.users[3].user}}</span>
                    <img class="team-av" :src="team_avatar($store.state.game.users[3].user)" @click="client_auth($store.state.game.users[3].user)">
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column text-white d-none animate__animated" v-if="$store.state.game.users[4].is_alive == false" id="team-av-4">
                    <span class="team-txt mb-4">EQUIPO<br/> {{$store.state.game.users[4].user}}</span>
                    <img class="team-av" :src="team_avatar($store.state.game.users[4].user)" @click="client_auth($store.state.game.users[4].user)">
                </div>
            </div>
            <div class="w-100 h-50 d-flex justify-content-around align-items-center">
                <div class="d-flex justify-content-center align-items-center flex-column text-white d-none animate__animated" v-if="$store.state.game.users[5].is_alive == false" id="team-av-5">
                    <img class="team-av" :src="team_avatar($store.state.game.users[5].user)" @click="client_auth($store.state.game.users[5].user)">
                    <span class="team-txt mt-4">EQUIPO<br/> {{$store.state.game.users[5].user}}</span>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column text-white d-none animate__animated" v-if="$store.state.game.users[6].is_alive == false" id="team-av-6">
                    <img class="team-av" :src="team_avatar($store.state.game.users[6].user)" @click="client_auth($store.state.game.users[6].user)">
                    <span class="team-txt mt-4">EQUIPO<br/> {{$store.state.game.users[6].user}}</span>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column text-white d-none animate__animated" v-if="$store.state.game.users[7].is_alive == false" id="team-av-7">
                    <img class="team-av" :src="team_avatar($store.state.game.users[7].user)" @click="client_auth($store.state.game.users[7].user)">
                    <span class="team-txt mt-4">EQUIPO<br/> {{$store.state.game.users[7].user}}</span>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column text-white d-none animate__animated" v-if="$store.state.game.users[8].is_alive == false" id="team-av-8">
                    <img class="team-av" :src="team_avatar($store.state.game.users[8].user)" @click="client_auth($store.state.game.users[8].user)">
                    <span class="team-txt mt-4">EQUIPO<br/> {{$store.state.game.users[8].user}}</span>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column text-white d-none animate__animated" v-if="$store.state.game.users[9].is_alive == false" id="team-av-9">
                    <img class="team-av" :src="team_avatar($store.state.game.users[9].user)" @click="client_auth($store.state.game.users[9].user)">
                    <span class="team-txt mt-4">EQUIPO<br/> {{$store.state.game.users[9].user}}</span>
                </div>
            </div>
        </div>
        <div v-if="all_clients_connected == false" class="position-absolute text-center title">
            <h1 class="fw-bold">SELECCIONA</h1>
            <h1 class="fw-bold">TU EQUIPO<br/></h1>
        </div>
        <div v-else class="position-absolute text-center title">
            <h1 class="fw-bold">LOS GRUPOS HAN SIDO SELECCIONADOS</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Auth',
    data(){
        return {
            utils: require('../../../shared/utils'),
            all_clients_connected: false,
            team_avatar: function(name){
                var img = null;
                try{
                    img = require('@/assets/' + name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.png')
                }catch(e){
                    console.log(e)
                }
                return img; 
            }
        }
    },
    watch:{
        '$store.state.game.users'(){
            this.$store.state.game.users.forEach((usr, idx) =>{
                this.animate_team(idx);
            });
            this.are_clients_connected();
        }
    },
    updated(){
        this.$store.state.game.users.forEach((usr, idx) =>{
            this.animate_team(idx);
        });
    },
    unmounted(){
        window.sound.pauseAudio('lobby');
    },
    mounted(){
        this.$store.state.game.users.forEach((usr, idx) =>{
            this.animate_team(idx);
        });
        if(this.$store.state.game.role == this.utils.USER_ROLES.MANAGER){
            window.sound.playAudio('lobby', 10, 10);
        }
    },
    methods : {
        are_clients_connected(){
            let connected = false;
            let disconnected = this.$store.state.game.users.filter((usr) => usr.is_alive == false).length;
            if(disconnected == 0)
                connected = true;
            this.all_clients_connected = connected;
        },
        client_auth(user){
            if(this.$store.state.game.role == utils.USER_ROLES.GUEST)
                window.network.wso.send(JSON.stringify({action: window.RPC.CLIENT_AUTH, params: user}));
        },
        animate_team(idx){
            setTimeout(function(){
                $('#team-av-'+idx).removeClass("d-none").addClass("animate__fadeIn");
            }, idx * 100);
        }
    }
}
</script>

<style scoped>
.title{
    color:rgb(110, 161, 255);
    font-family: "Nulshock Rg";
    
    -webkit-animation: bounce 3s infinite ease-in-out;
    -o-animation: bounce 3s infinite ease-in-out;
    -ms-animation: bounce 3s infinite ease-in-out; 
    -moz-animation: bounce 3s infinite ease-in-out; 
    animation: bounce 3s infinite ease-in-out;
}

#video_fondo_seleccion{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.team-txt{
    font-family: "Nulshock Rg";
    text-align: center;
    font-size: 1.5vw;
}

.team-av{
    width: 10vw;
    border-radius: 100%;
    border: 2px solid white;
    -webkit-box-shadow: 0px 0px 15px 7px white;
    -moz-box-shadow: 0px 0px 15px 7px white;
    box-shadow: 0px 0px 15px 7px white;
}

@-webkit-keyframes bounce {
    0% { transform: translateY(-5px)  }
    50% { transform: translateY(10px) }
    100% { transform: translateY(-5px) }
}

@keyframes bounce {
    0% { transform: translateY(-5px)  }
    50% { transform: translateY(10px) }
    100% { transform: translateY(-5px) }
}

</style>