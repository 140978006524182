import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/Auth.vue'
import Login from '../views/Login.vue'
import Lobby from '../views/Lobby.vue'
import Game from '../views/Game.vue'
import Panel from '../views/Panel.vue'
import End from '../views/End.vue'
import Title from '../views/Title.vue'
import Activity from '../views/Activity.vue'
import Result from '../views/Result.vue'
import Ranking from '../views/Ranking.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/title',
        name: 'Title',
        component: Title
    },
    {
        path: '/activity',
        name: 'Activity',
        component: Activity
    },
    {
        path: '/result',
        name: 'Result',
        component: Result
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth
    },
    {
        path: '/panel',
        name: 'Panel',
        component: Panel,
    },
    {
        path: '/end',
        name: 'End',
        component: End,
    },
    {
        path: '/lobby',
        name: 'Lobby',
        component: Lobby
    },
    {
        path: '/game',
        name: 'Game',
        component: Game
    },
    {
        path: '/ranking',
        name: 'Ranking',
        component: Ranking,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.afterEach((to, from, failure) => {
    window.store.commit("setCurrentView", to);
})

export default router
